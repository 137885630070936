import React from "react";
import FlippableCard from "../FlippableCard/FlippableCard";
import { Slide } from "react-awesome-reveal";
import "../Card/CardContainer.css";

import beckettlogo from "../../assets/beckettcorp.jpg";
import clevelandclinic from "../../assets/clevelandclinic.png";
import universityoftoledo from "../../assets/ut.png";

const Experience = () => {
  return (
    <div className="card-container" id="experience">
      <div className="section-header">
      <Slide direction="down">
        <h1 className="section-title">Experience</h1>
        </Slide>
      </div>
      <div className="cards">
        <Slide direction="left">
          <FlippableCard
            imageUrl={clevelandclinic}
            title={"Unit Support Assistant / Health Unit Coordinator"}
            subDescription1={"Cleveland Clinic"}
            subDescription2={"June 2017 - January 2021"}
            backText={
              `• Communicate with coworkers to complete the job efficiently and maintain a sterile environment

              • Track and move inventory of supplies throughout the unit and other units within the hospital

              • Store patient information in an accessible and organized manner

              • Help patients and their families locate facilities throughout the unit and hospital 

              • Utilize computer programs such as Epic and Watch Child to obtain patient info to track and monitor the safety of patients throughout the units`
            }
            backgroundImage={'linear-gradient(159deg, #6E45E1 0%, #89D4CF 100%)'}
          />
        </Slide>
        <Slide direction="right">
          <FlippableCard
            imageUrl={beckettlogo}
            title={"Software Engineering Co-op/Intern"}
            subDescription1={"R.W. Beckett Corp."}
            subDescription2={"August 2022 - December 2022 \n May 2023 - August 2023"}
            backText={
              `• Developed a frontend web application using Python with the Dash framework to analyze factory line productivity from an internal SQL Server database

              • Built a mobile application using the React Native framework and TypeScript, focusing on user interface design and compatibility with oil burner control products

              • Conducted unit testing with the Jest JavaScript testing framework

              • Performed physical product testing including: documentation, procedure, and reliability to ensure oil burner controls were up to standard and were compatible with the mobile application

              • Agile workflow for product designing, development, and deployment

              • Utilized version control including GitLab for issues, branches, merge requests, etc.
              `
            }
            backgroundImage={'linear-gradient(159deg, #6E45E1 0%, #89D4CF 100%)'}
          />
        </Slide>
        <Slide direction="left">
          <FlippableCard
            imageUrl={universityoftoledo}
            title={"Academic Industry Immersion Seminar"}
            subDescription1={"University Of Toledo"}
            subDescription2={"June 2020 - August 2020"}
            backText={
              `• Designed a hand sanitizer factory adhering to EPA standards in response to the COVID-19 pandemic

              • Acquired technical skills through online certifications on Lynda/LinkedIn, including Python for Students, Advanced Java Programming, and Learning C++

              • Completed KEEN Entrepreneurial Mindset Modules covering Lifelong Learning, Learning from Failure, and Developing Business Plans Addressing Stakeholder Interests
              
              • Identified and analyzed my Myers-Briggs Type Indicator results to optimize team collaboration and performance
              `
              
            }
            backgroundImage={'linear-gradient(159deg, #6E45E1 0%, #89D4CF 100%)'}
          />
        </Slide>
        </div>
        </div>
  );
};

export default Experience;
