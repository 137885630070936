import ProgressBarsRow from "../ProgressBar/ProgressBarRow"
import { Slide } from "react-awesome-reveal";

const Skills = () => {
return (
<div id="skills" style={{
      width: '80%',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '3rem 0'}}>
      <div className="section-header">
      <Slide direction="down">
        <h1 className="section-title">Skills</h1>
        </Slide>
      </div>
      <ProgressBarsRow progress1={70} progress2={90} label1={'TypeScript'} label2={'JavaScript'}/>
      <ProgressBarsRow progress1={90} progress2={60} label1={'React.js'} label2={'REST APIs'}/>
      <ProgressBarsRow progress1={80} progress2={70} label1={'HTML/CSS'} label2={'React Native'}/>
      <ProgressBarsRow progress1={90} progress2={40} label1={'GIT'} label2={'ARM Assembly'}/>
      <ProgressBarsRow progress1={75} progress2={65} label1={'C'} label2={'C++'}/>
      <ProgressBarsRow progress1={60} progress2={60} label1={'Java'} label2={'SQL'}/>
      <ProgressBarsRow progress1={75} progress2={50} label1={'Square D PLC Programming'} label2={'C#'}/>
      <ProgressBarsRow progress1={55} progress2={45} label1={'Python'} label2={'x86 Assembly'}/>
      <ProgressBarsRow progress1={80} progress2={80} label1={'Google Drive Suite'} label2={'Microsoft Office 365'}/>
</div>
)
}

export default Skills;