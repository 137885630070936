
import React from 'react';
import { GiSasquatch } from "react-icons/gi";

const SasquatchButton = () => {
    return (
      <button style={styles.button} onClick={() => {
        window.open("https://sasquatch-dashboard-34157.web.app/#/", "_blank", "noreferrer");
      }}>
        <GiSasquatch />
      </button>
    );
  };
  
  const styles = {
    button: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px 40px',     // Reduced padding to shrink the button size slightly
      fontSize: '36px',         // Reduced font size accordingly
      backgroundColor: 'transparent', // Transparent background
      color: 'white',
      border: '2px solid white', // Optional: Add a border to make the button visible on different backgrounds
      borderRadius: '4px',
      cursor: 'pointer',
      marginLeft: '10px',
    },
    icon: {
      marginRight: '16px',      // Reduced margin to match the smaller button
      fontSize: '42px',         // Reduced icon size accordingly
    }
  };
  
  export default SasquatchButton;